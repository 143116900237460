/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from 'query-string';
import {url} from '../helper';

import {getCategories, getCategoryBySlug} from '../fake-server/endpoints/categories';
import {
    getDiscountedProducts,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductBySlug,
    getProductsList,
    getRelatedProducts,
    getSuggestions,
    getTopRatedProducts,
} from '../fake-server/endpoints/products';
import {FormattedMessage} from "react-intl";
import {toast} from "react-toastify";
import React from "react"

const shopApi = {
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    sendConfirm: ({options}) => {

        return fetch(`${url}/api/checkout/group-user/cart/confirm`, options)
            .then((response) => response.json());

    },

    getFilters: (options = {}) => {
        return fetch(`${url}/api/filters?category_id=${options.category_id}&locale=${options.locale}`)
            .then((response) => response.json());
        // /api/attributes?code=brand&locale=  // before was it  category_id

        // return fetch(`${ url }/api/attributes?code=brand&locale=${options.locale}`)
        //     .then((response) => response.json());
    },

    getCategories: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */

        return await fetch(`${url}/api/categories?locale=${options.locale}`)
            .then((response) => (response.json()));

        // This is for demonstration purposes only. Remove it and use the code above.
        return getCategories(options);
    },

    getMenus: (options = {}) => {
        return fetch(`${url}/api/cms/menus?locale=${options.locale}`)
            .then((response) => response.json())
    },

    // getFilters: (catID) => {
    //     fetch(`${url}/api/filters?category_id=${catID}`)
    //             .then((responce) => responce.json())


    // },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    // getCategoryBySlug: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
    //     return getCategoryBySlug(slug, options);
    // },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */
    getProductBySlug: (slug) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/screwdriver-a2017.json
         *
         * where:
         * - screwdriver-a2017 = slug
         */
        // return fetch(`https://example.com/api/products/${slug}.json`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductBySlug(slug);
    },
    /**
     * Returns array of related products.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
    getRelatedProducts: (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getRelatedProducts(slug, options);
    },
    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: (options = {}, filters = {}, location) => {

        for (let filter in filters) {

            if (filters[filter] === '') {
                delete filters[filter]
            }

        }
        if (options.savings === '') {
            delete options.savings
        }

        // if (qs.stringify(options) === '' && qs.stringify(filters) === '') {
        //
        //     return fetch(`${url}/api/products?limit=`)
        //         .then(responce => responce.json())
        // } else {

        return fetch(`${url}/api/products?${qs.stringify(options) + '&'}${qs.stringify(filters) === '' ? '&' : qs.stringify(filters) + '&'}limit=${filters.limit}&page=${filters.currentPage}`)
            .then(responce => responce.json())
        // }


        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        // const params = { ...options };
        //
        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        //
        // return fetch(`https://example.com/api/products.json?${qs.stringify(params)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductsList(options, filters);
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getFeaturedProducts(options);
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getLatestProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(options);
    },
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getTopRatedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRatedProducts(options);
    },
    /**
     * Returns an array of discounted products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getDiscountedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getDiscountedProducts(options);
    },
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getPopularProducts(options);
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: (query, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getSuggestions(query, options);
    },
    getProductsListBySearch: (props) => {
        const {search, locale = 'en', limit, category_id, token, page} = props;
        const catStr = category_id ? `&category_id=${category_id}` : '';
        const userToken = token ? `token=${token}` : ""
        return fetch(`${url}/api/search?search=${search}&locale=${locale}&page=${page}&limit=${limit}&token=${userToken}${catStr}`)
            .then(responce => responce.json())
    },
    /*get multiple requests for products so they can be downloaded asynchronous*/
    getMultipleRequests: (prodId, lang = 'hy', currency = 'AMD') => {
        return Promise.all([
            //   fetch(`${url}/api/products/attrs/${prodId}`),
            //   fetch(`${url}/api/products/variants/${prodId}?locale=${lang}&currency=${currency}&type=simple`),
            fetch(`${url}/api/product-configurable-config/${prodId}?locale=${lang}`),
        ]).then(responses => {
            return Promise.all(responses.map(response => response.json()))
        })
    },
    getProductById: (id, locale = 'hy') => {
        return fetch(`${url}/api/products/${id}?locale=${locale}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(responce => responce.json())
    },

    getProductByUrlKey: (url_key, locale = 'hy') => {
        return fetch(`${url}/api/product/${url_key}?locale=${locale}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(responce => responce.json())
    },

    getProductAttributes: (id, locale = 'hy') => {
        return fetch(`${url}/api/product-additional-information/${id}?locale=${locale}`)
            .then(responce => responce.json())
    },

    getChannelData: () => {
        return fetch(`${url}/api/channel/1`)
            .then(responce => responce.json())
    },

    getCategoryByID: (cat_id, locale) => {
        return fetch(`${url}/api/category-by-id/${cat_id}?locale=${locale}`)
            .then(responce => responce.json())
    },

    getCategoryBySlug: (slug, locale) => {
        return fetch(`${url}/api/category/${slug}?locale=${locale}`)
            .then(responce => responce.json())
    },

    cartRemoveItem: async ({ productId, queryParam, customerRole }) => {
        try {
            const weHaveGroupedUser = {
                "true": "/group-user",
                "false": ""
            }
            const response = await fetch(`${url}/api/checkout${weHaveGroupedUser[customerRole === "subUser"]}/cart/remove-item/${productId}${queryParam}`)
            return await response.json()
        } catch (err) {
            console.log(err)
        }
    },

    cartAddItem: async ({ productId, body, customerRole }) => {
        try {
            const weHaveGroupedUser = {
                "true": "/group-user",
                "false": ""
            }
            const response = await fetch(`${url}/api/checkout${weHaveGroupedUser[customerRole === "subUser"]}/cart/add/${productId}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            let message = <FormattedMessage id="successfully.added" defaultMessage={response.message} />;
            toast.success(message);
            return await response.json();
        } catch (err) {
            console.log(err)
        }
    },

    cartQuantityUpdate: async ({body, customerRole}) => {
        try {
            const weHaveGroupedUser = {
                "true": "/group-user",
                "false": ""
            }
            const response = await fetch(`${url}/api/checkout${weHaveGroupedUser[customerRole === "subUser"]}/cart/update`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
            return await response.json();
        } catch (err) {
            console.log(err)
        }
    },

    getCartData: async ({cartToken, customerToken, locale, customerRole}) => {
        try {
            const weHaveGroupedUser = {
                "true": "/group-user",
                "false": ""
            }
            return fetch(`${url}/api/checkout${weHaveGroupedUser[customerRole === "subUser"]}/cart?api_token=${cartToken}${customerToken}&locale=${locale}`)
                .then(res => res.json())
        } catch (err) {
            console.log(err)
        }
    },
    syncCartData: async ({token, already_added}) => {
        try {
            return fetch(`${url}/api/checkout/cart/sync`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token, already_added }),
            })
                .then(res => res.json())
        } catch (err) {
            console.log(err)
        }
    },
};

export default shopApi;
