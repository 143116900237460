import classNames from "classnames";
import {setSearchedWord} from '../../store/general';
import {useHistory, useLocation, withRouter} from "react-router-dom";
import React, {useCallback, useEffect, useRef, useState} from "react";

// application
import shopApi from "../../api/shop";
import Suggestions from "./Suggestions";
import {SearchCrossSVG, SearchIconBigSVG} from "../../svg";
import {connect, useSelector} from "react-redux";
import queryString from "query-string";
import {FormattedMessage} from "react-intl";
import {useWindowWidth} from "../../hooks/useWindowWidth";

// function useCategories() {
//     const [categories, setCategories] = useState([]);
//     const locale = useSelector((state) => state.locale);
//
//     useEffect(() => {
//         let canceled = false;
//
//         shopApi.getCategories({ locale: locale }).then((categories) => {
//             if (canceled) {
//                 return;
//             }
//             setCategories(categories.categories);
//         });
//
//         return () => {
//             canceled = true;
//         };
//     }, [locale, setCategories]);
//
//     return categories;
// }

// function init(state) {
//     const [options, filters] = parseQuery(window.location.search);
//     return { ...state, options, filters };
// }

function Search(props) {
    const {
        context,
        className,
        // inputRef,
        onClose,
        // location,
    } = props;
    const history = useHistory();
    const location = useLocation();
    const width = useWindowWidth()

    const [cancelFn, setCancelFn] = useState(() => () => {
    });
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [query, setQuery] = useState("");

    // const [product, setProduct] = useState(null);
    const [category, _] = useState("");
    // const categories = useCategories();
    const wrapper = useRef(null);
    const GeneralCatID = useSelector((state) => state.general.catID);
    const [categoryName, setCategoryName] = useState(false);
    const [focused, setIsFocused] = useState(false)
    const [entered, setEntered] = useState(false)
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setSuggestionsOpen(false);
    }, [onClose]);

    const locale = useSelector(state => state.locale)

    useEffect(() => {
        return () => props.setSearchedWord("")
    }, [])

    useEffect(() => {
        const {search} = queryString.parse(window.location.search);
        if (search) {
            setQuery(search);
        } else setQuery("")
    }, [location]);

    useEffect(() => {
        !entered && setSuggestionsOpen(false)
    }, [entered])


    // Close suggestions when the location has been changed.
    useEffect(() => close(), [close, location]);

    // Close suggestions when a click has been made outside component.
    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener("mousedown", onGlobalClick);

        return () => document.removeEventListener("mousedown", onGlobalClick);
    }, [close]);

    // Cancel previous typing.
    useEffect(() => () => cancelFn(), [cancelFn]);

    const handleFocus = () => {
        setIsFocused(true)
        setSuggestionsOpen(true);
    };
    const handleFocusOut = () => {
        setIsFocused(false)
    }


    const handleChangeQuery = (event) => {
        let canceled = false;
        let timer;

        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        const query = event.target.value;

        setQuery(query);

        if (query === "") {
            setHasSuggestions(false);
            setSuggestionsOpen(false);
        } else {
            timer = setTimeout(() => {
                const options = {search: query, limit: 10, locale};

                if (category !== "[all]") {
                    options.category = category;
                }

                shopApi.getProductsListBySearch(options).then((products) => {
                    if (canceled) {
                        return;
                    }

                    if (products?.data?.length) {
                        setSuggestedProducts(products?.data);
                        setHasSuggestions(products?.data?.length > 0);
                        setSuggestionsOpen(true);
                    } else {
                        setSuggestedProducts([{name: query, id: -1}]);
                        setHasSuggestions(products?.data?.length === 0);
                        setSuggestionsOpen(true);

                    }
                    setEntered(false)
                });
            }, 100);
        }

        setCancelFn(() => newCancelFn);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    const buttonClick = (event) => {
        event.preventDefault();
        props.setSearchedWord(query)
        setEntered(true)

        // history.push('/shop/main/catalog/search')
        if (Boolean(query)) {
            const params = queryString.parse(window.location.search);
            // if (category) {
            //     history.push({
            //         pathname: "/shop/main/catalog/search",
            //         search: queryString.stringify({
            //             ...params,
            //             search: query,
            //             category_id: category,
            //         }),
            //     });
            // } else {
            history.push({
                pathname: `/${locale}/shop/main/catalog/search`,
                search: queryString.stringify({
                    ...params,
                    search: query,
                }),
            });
        }
        setQuery("")
        setHasSuggestions(false);
        setSuggestionsOpen(false);
        // }
    };

    // Close suggestions when the Escape key has been pressed.
    // const handleKeyDown = (event) => {
    //     // Escape.
    //     if (event.which === 27) {
    //         close();
    //     }
    //     if (event.which === 13) {
    //         event.preventDefault();
    //         const params = queryString.parse(window.location.search);
    //         history.push({
    //             pathname: "/shop/main/catalog",
    //             search: queryString.stringify({
    //                 ...params,
    //                 search: query,
    //                 category_id: category,
    //             }),
    //         });
    //     }
    // };

    const rootClasses = classNames(`search search--location--${context}`, className, {
        "search--suggestions-open": suggestionsOpen,
        "search--has-suggestions": hasSuggestions,
    });


    const searchButton = <button
        className="search__button search__button--type--submit"
        type="submit"
        // onClick={buttonClick}
    >
        <SearchIconBigSVG/>
    </button>

    const tree = (categories, depth) => {
        return categories.map((category) => {
            let level = 0;

            if (GeneralCatID === category.id) {
                categoryName !== category.name && setCategoryName(category.name)
            }

            if (GeneralCatID === " ") {
                categoryName !== false && setCategoryName(false)
            }

            if (category.children.length > 0) {
                return (
                    <React.Fragment key={category.id}>
                        {level + 1}
                        <option value={category.id} id={category.id}>
                            {category.name}
                        </option>
                        {tree(category.children, level + 1)}
                    </React.Fragment>
                );
            }
            return (
                <option key={category.id} value={category.id}>
                    {"\u00A0".repeat(4 * depth)}
                    {category.name}
                </option>
            );
        });
    };
    return (
        <>
            <div id={"search"} className={rootClasses} ref={wrapper} onBlur={handleBlur}>
                <div className="search__body">
                    <form className="search__form" onSubmit={buttonClick}>
                        {/* {window.location.pathname !=='/shop/catalog'? */}
                        {/* <select
                            className="search__categories d-none d-md-block"
                            aria-label="Category"
                            value={category}
                            onFocus={close}
                            onChange={handleChangeCategory}
                        >
                              {GeneralCatID && categoryName!= false?
                               <FormattedMessage id={categoryName} defaultMessage={categoryName}>
                                {(messagea) => <option value="">{messagea}</option>}
                              </FormattedMessage>

                              :  "" }
                              <FormattedMessage id="all_categories" defaultMessage="All Categories">
                                {(message) => <option value="">{message}</option>}
                              </FormattedMessage>

                            {tree(categories)}
                        </select> */}
                        {!focused && width < 767 ? searchButton : ""}
                        <div style={{display: "flex"}}>
                            {/* queryString.parse(location.search).search */}
                            <FormattedMessage id="search" defaultMessage="Search">
                                {(message) => <input
                                    value={query}
                                    onChange={handleChangeQuery}
                                    onFocus={handleFocus}
                                    onBlur={handleFocusOut}
                                    //  onKeyDown={handleKeyDown}
                                    placeholder={message}
                                    className="search__input"
                                    name="search"
                                    aria-label="Site search"
                                    type="text"
                                    autoComplete="off"
                                />}

                            </FormattedMessage>
                            {
                                query ?
                                    <button
                                        className="search__button search_close_button"
                                        // type="submit"
                                        // onClick={buttonClick}
                                    >

                                        <SearchCrossSVG onClick={() => setQuery("")}/>
                                    </button>
                                    : width >= 767 ?
                                        searchButton
                                        : ""
                            }
                        </div>
                        {/* {closeButton} */}
                    </form>
                    <div className="search__border"/>
                </div>
                <Suggestions
                    className="search__suggestions"
                    context={context}
                    products={suggestedProducts}
                    setQuery={setQuery}
                    setSuggestionsOpen={setSuggestionsOpen}
                />
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setSearchedWord: (payload) => dispatch(setSearchedWord(payload)),
    // setCatId: (catId) => dispatch(setCatId(catId))
})
export default connect(false, mapDispatchToProps)(withRouter(Search));
