// react
import React from 'react';
import {Link} from "react-router-dom";

function Breadcrumbs(props) {
    const {breadcrumb} = props;

    return (
        <div className="breadcrumbs">
            <div className="container navigation">
                {breadcrumb.map((el, index) => {
                    return <>
                        <Link key={index} to={el.url} className={`path ${index === breadcrumb.length - 1 && "last_path"}`}>{el.title}</Link>
                        {index !== breadcrumb.length - 1 && <div>/</div>}
                    </>
                })}
            </div>
        </div>
    );
}


export default Breadcrumbs;
