import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import {getCartDataMount} from '../../store/cart';
import {ArrowRoundedDown12x7Svg, ArrowRoundedRight8x13Svg} from '../../svg'
import shopApi from "../../api/shop";

function AllPurchases({
                          token,
                          itemsLength,
                          callback,
                          isLoadingSync,
                          allSyncedCartItems,
                      }) {
    const [openedList, setOpenedList] = useState([]);
    const [allSyncedCartItems1, setAllSyncedCartItems] = useState();
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.customer);
    const cart = useSelector((state) => state.cart);
    const [skipCount, setSkipCount] = useState(true);

    const alreadyAddedCartData = cart.items?.map(item => ({
        [item.id]: item.quantity
    }));

    const alreadyAddedInCart = Object.assign({}, ...alreadyAddedCartData);

    useEffect(() => {
        if (skipCount) setSkipCount(false);
        if (!skipCount) {
            shopApi.syncCartData({ token, already_added: alreadyAddedInCart })
                .then(res => setAllSyncedCartItems(res?.[0]?.cart))
            dispatch(getCartDataMount());
        }
    }, [isLoadingSync]);

    const makeConfirmedItems = allSyncedCartItems1?.map((item, index) => {
        if (!item) {
            return null
        }
        return (
            <div className={`sync-products-cart-page ${openedList.indexOf(item.product_name) !== -1 ? 'open' : ''}`}
                 key={index}>
                <h2 onClick={() => {
                    if (openedList.indexOf(item.product_name) !== -1) {
                        setOpenedList(openedList.filter(e => e !== item.product_name))
                    } else {
                        setOpenedList([...openedList, item.product_name])
                    }
                }}>
                    {item.product_name}
                    {openedList.indexOf(item.product_name) !== -1 ? <ArrowRoundedDown12x7Svg/> : <ArrowRoundedRight8x13Svg/>}
                </h2>
                <div>
                    <table className="cart_table_fm d-md-table">
                        <tbody>
                            <tr className="border_bottom">
                                <td className="cart_block_content cart_block_content_img first_td_item">
                                    <img
                                        src={`/${item.product_image}`}
                                        alt=""
                                        height="120px"
                                    />
                                </td>
                                <td className="cart_block_content">{item.product_name}</td>
                                <div className="block_contentfm">
                                    <td className="cart_block_content">
                                        {Math.round(item.product_price)}
                                        <FormattedMessage
                                            id="global.curency"
                                            defaultMessage="AMD"
                                        />
                                    </td>
                                    <td className="cart_block_content">x</td>
                                    <td className="cart_block_content">
                                        {item.quantity}
                                    </td>
                                    <td className="cart_block_content">
                                        {Math.round(item.quantity * item.product_price)}
                                        <FormattedMessage
                                            id="global.curency"
                                            defaultMessage="AMD"
                                        />
                                    </td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    })
    return (
        <div>{makeConfirmedItems}</div>
    )
}

const mapStateToProps = (state) => {
    return {
        allSyncedCartItems: state.cart.items,
    };
};
//
// const mapDispatchToProps = {
//     saveSyncedAllProducts
// }

export default connect(
    mapStateToProps,
    // mapDispatchToProps
)(React.memo(AllPurchases));


//TODO Sync cart data backup
// useEffect(() => {
//     if (skipCount) setSkipCount(false);
//     if (!skipCount) {
//         const options = {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({token, already_added}),
//         };
//
//         fetch(`${url}/api/checkout/cart/sync`, options)
//             .then((response) => response.json())
//             .then((res) => {
//
//                 if (res.length > 0) {
//                     let filtered = res.map(e => {
//                         if (!e.cart) return null
//                         return e.cart.map(e => e)
//                     })
//                         .filter(e => e)
//                         .reduce((prev, next) => {
//                             return [...prev, ...next]
//                         }, [])
//                     // console.log(filtered, "filtereeeeed");
//                     // const AlreadyUpdatedIds = Object.keys(JSON.parse(filtered[0].already_updated)).map(item => {
//                     //     return Number(item)
//                     // })
//                     // const AlreadyUpdatedSummaryQtys = Object.values(JSON.parse(filtered[0].already_updated))
//                     // console.log(AlreadyUpdatedSummaryQtys, "AAAAAAAAAAAAAAAAAAAAAAAA");
//
//                     const mergedCartsForAddToCart = filtered.reduce((prev, next) => {
//                         // isClicked
//                         if (prev[next.product_id]) {
//                             // console.log(prev[next.product_id].quantity + next.quantity, "prev");
//
//                             return {
//                                 ...prev,
//                                 [next.product_id]: {
//                                     ...prev[next.product_id],
//                                     id: next.product_id,
//                                     price: prev[next.product_id].product_price,
//                                     base_image: {original_image_url: prev[next.product_id].product_image},
//                                     quantity: prev[next.product_id].quantity + next.quantity
//                                 }
//                             }
//                         }
//                         return {
//                             ...prev,
//                             [next.product_id]: {
//                                 ...next,
//                                 id: next.product_id,
//                                 price: next.product_price,
//                                 base_image: {original_image_url: next.product_image},
//                             }
//                         }
//                     }, {})
//
//                     // console.log(mergedCartsForAddToCart, "finally structure");
//                     new Promise(resolve => {
//                         setTimeout(() => {
//                             const items = Object.values(mergedCartsForAddToCart);
//                             // console.log(items, "items!")
//                             items.forEach((product, index) => {
//
//                                 // console.log(product, "product!!!!!!!!!!!+++++++++++++")
//                                 if (product.old_quantity !== product.quantity || product.old_quantity < product.quantity) {
//                                     const totalQty = product.quantity - product.old_quantity
//                                     // console.log(totalQty,product.quantity,product.old_quantity,"sq");
//                                     return cartAddItem(
//                                         product,
//                                         [],
//                                         +totalQty,
//                                         cart.cart_token,
//                                         customer.token ? customer.token : null,
//                                         resolve,
//                                         index + 1 === items.length
//                                     )
//                                 }
//
//
//                             })
//                             saveSyncedAllProducts(res)
//                             callback()
//
//                         }, 500)
//                     }).then(resData => {
//                         // if (res) {
//                         //     saveSyncedAllProducts(res)
//                         // }
//                     })
//                 }
//             })
//     }
// }, [isLoadingSync]);
